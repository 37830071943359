import { Container } from "../../components/container";
import PageTitle from "../../components/page-title";
import { TicketLineList } from "../../components/lists/ticket";
import { Ticket } from "../../model";
import { useLoaderData, useRevalidator } from "react-router-dom";

export function BacklogBoardView() {
    const tickets = useLoaderData() as Ticket[];
    const revalidator = useRevalidator();

    function reload() {
        revalidator.revalidate();
    }

    return <Container>
        <PageTitle title="Backlog"/>

        <div>
            <TicketLineList title="In Progress" dropId="inprogress" tickets={tickets.filter(t => t.status === "IN_PROGRESS")} requestReload={reload}/>
        </div>

        <div className="mt-5">
            <TicketLineList title="Open" dropId="open" tickets={tickets.filter(t => t.status === "OPEN")} requestReload={reload}/>
        </div>
    </Container>
}