import { SubmitHandler, useForm } from "react-hook-form";
import { Project, Repository } from "../../model";

export function RepositoryForm(props: RepositoryFormProps) {
    const {register, handleSubmit, formState: { errors },} = useForm<Repository>({
        defaultValues: props.existing || {}
    })

    const onSubmit: SubmitHandler<Repository> = (repository: Repository) => {
        props.onSubmit(repository);
    };

    return (
        <div className="container p-2 mx-auto">
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="provider">Provider</label>
                    <div className="flex-1">
                        <select id="provider" {...register("provider", { required: true })} className="inline-block w-full p-2 bg-gray-100 border-2 border-gray-300 rounded dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-600">
                            <option value="GITHUB">GitHub</option>
                        </select>
                        {errors.provider && <span>This field is required</span>}
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="project">Project</label>
                    <div className="flex-1">
                        <select id="project" {...register("project_id", { required: true })} className="inline-block w-full p-2 bg-gray-100 border-2 border-gray-300 rounded dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-600">
                            { props.projects.map(project => {
                                return <option value={project.id}>{project.key} - {project.name}</option>
                            })}
                        </select>
                        {errors.project_id && <span>This field is required</span>}
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="title">Name</label>
                    <div className="flex-1">
                        <input id="name" {...register("name", { required: true })} className="inline-block w-full p-2 bg-gray-100 border-2 border-gray-300 rounded dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-600" />
                        {errors.name && <span>This field is required</span>}
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="title">URL</label>
                    <div className="flex-1">
                        <input id="url" {...register("url", { required: true })} className="inline-block w-full p-2 bg-gray-100 border-2 border-gray-300 rounded dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-600" />
                        {errors.url && <span>This field is required</span>}
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="title">Description</label>
                    <div className="flex-1">
                        <input id="description" {...register("description", { required: true })} className="inline-block w-full p-2 bg-gray-100 border-2 border-gray-300 rounded dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-600" />
                        {errors.description && <span>This field is required</span>}
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <div className="w-2/12"></div>
                    <button type="submit" className="p-2 text-white bg-blue-600 rounded">Save</button>
                </div>
            </form>
        </div>
    );
}

export interface RepositoryFormProps {
    existing?: Repository;
    onSubmit: (repository: Repository) => void;
    projects: Project[];
}