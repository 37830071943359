import { Link, useLoaderData } from "react-router-dom"
import { Project, Ticket } from "../../model";
import { useEffect, useState } from "react";
import { APIService } from "../../services/api";
import { getTicketTypeIcon } from "../../utils/ui";
import { Container } from "../../components/container";
import { toast } from "react-toastify";

export function TreeView() {
    const projects = useLoaderData() as Project[];

    return <Container>
        { projects.map(project => {
            return <TreeNode type="project" object={project} key={project.id} root={true} />
        })}
    </Container>
}

export function TreeNode(props: any) {
    const [expanded, setExpanded] = useState(false);
    const [childProjects, setChildProjects] = useState<Project[]>([]);
    const [tickets, setTickets] = useState<Ticket[]>([]);

    useEffect(() => {
        if(!expanded) {
            return;
        }

        switch(props.type) {
            case "project":
                // sub-projects
                APIService.getProjects("?parent_id=eq." + props.object.id + "&order=key")
                .then(projects => setChildProjects(projects))
                .catch((e: Error) => {
                    toast.error(e.message);
                })

                // top level tickets
                APIService.getTickets("?project_id=eq." + props.object.id + "&parent_id=is.null&order=created_at.desc")
                .then(tickets => setTickets(tickets))
                .catch((e: Error) => {
                    toast.error(e.message);
                })

                break;
            case "ticket":
                // tickets below this ticket
                APIService.getTickets("?parent_id=eq." + props.object.id + "&order=created_at.desc")
                .then(tickets => setTickets(tickets))
                .catch((e: Error) => {
                    toast.error(e.message);
                })
        }
    }, [expanded, props.object.id, props.type]);

    return <div className="border-t border-gray-100 dark:border-slate-700">
        <div className={props.root ? "" : "pl-5"} key={props.type + "-" + props.object.id}>
            <div className="p-2 cursor-pointer">

                <div className="inline-block w-12">
                    { (props.type === "project" || props.object.type === "EPIC") && 
                        <div className="inline" onClick={() => setExpanded(!expanded)}>
                            <i className={`m-4 bi ${expanded ? "bi-chevron-down" : "bi-chevron-right"}`}></i>
                        </div>
                    }
                </div>

                { props.type === "project" ? <div className="inline">
                    <Link to={`/project/${props.object.key}`}>
                        <div className="inline-block w-16 font-semibold">{ props.object.key}</div> 
                        <div className="inline ml-5">{props.object.name}</div>
                    </Link>
                </div> : null}

                { props.type === "ticket" && <div className="inline">
                    <Link to={`/ticket/${props.object.key}-${props.object.number}`}>
                        { getTicketTypeIcon(props.object.type) } 
                        <div className={`inline-block w-24 ml-2 font-semibold ${(props.object.status === "DONE" || props.object.status === "WONT_DO") ? "line-through" : ""}`}>
                            { props.object.key}-{props.object.number}
                        </div> 
                        <div className={`inline ml-20 ${(props.object.status === "DONE" || props.object.status === "WONT_DO") ? "line-through" : ""}`}>{props.object.title}</div>
                    </Link>
                </div>}
            </div>
            { expanded && <div>
                { childProjects.map(project => {
                    return <TreeNode object={project} type="project" />
                })}

                { tickets.map(ticket => {
                    return <TreeNode object={ticket} type="ticket"/>
                })}
            </div> }
        </div>
    </div>
}

export interface ProjectTreeNodeProps {
    type: "project" | "epic" | "story";
    object: Project | Ticket;
}