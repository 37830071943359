import { Link, useLoaderData } from "react-router-dom";
import { Repository } from "../../model";
import { RepositoryList } from "../../components/lists/repository";
import { Container } from "../../components/container";
import { Button } from "@joushx/react-components";

export function RepositoryListView() {
    let repositories = useLoaderData() as Repository[];

    return <Container>
        <div className="mb-5">
            <Link to="/repository/new">
                <Button>Create Repository</Button>
            </Link>
        </div>

        <RepositoryList repositories={repositories}/>
    </Container>
}