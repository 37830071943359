import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import {
  createHashRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { KanbanBoardView } from './views/boards/kanban';
import { Login } from './views/auth/login';
import { authLoader } from './utils/auth-loader';
import { Callback } from './views/auth/callback';
import { Layout } from './views/layout/Layout';
import { NotFound } from './views/error/not-found';
import { APIService } from './services/api';
import { ProjectsView } from './views/project/list';
import { BacklogBoardView } from './views/boards/backlog';
import { TicketListView } from './views/ticket/list';
import { TicketView } from './views/ticket/view';
import { TicketCreateView } from './views/ticket/create';
import { ErrorView } from './views/error/error';
import { TicketUpdateView } from './views/ticket/update';
import { TreeView } from './views/boards/tree';
import { ProjectView } from './views/project/view';
import { ProjectCreateView } from './views/project/create';
import { ProjectUpdateView } from './views/project/update';
import { RepositoryListView } from './views/repository/list';
import { RepositoryUpdateView } from './views/repository/update';
import { RepositoryView } from './views/repository/view';
import { BoardsView } from './views/boards/boards';
import { CalendarView } from './views/boards/calendar';
import { RepositoryCreateView } from './views/repository/create';
import "@joushx/react-components/dist/style.css";

const router = createHashRouter([
  {
    path: "/",
    element: <Layout/>,
    loader: authLoader,
    errorElement: <ErrorView/>,
    children: [
      {
        index: true,
        element: <Navigate to="/boards/kanban"/>
      },
      {
        path: "/boards",
        element: <BoardsView/>,
        children: [
          {
            index: true,
            element: <Navigate to="/boards/kanban"/>
          },
          {
            path: "kanban",
            loader: async function() {
              const [projects, open, inProgress, wontdo, done] = await Promise.all([
                APIService.getProjects(""),
                APIService.getTickets("?status=eq.OPEN&type=neq.EPIC&order=last_activity_at.desc"),
                APIService.getTickets("?status=eq.IN_PROGRESS&type=neq.EPIC&order=last_activity_at.desc"),
                APIService.getTickets("?status=eq.WONT_DO&type=neq.EPIC&order=last_activity_at.desc"),
                APIService.getTickets("?status=eq.DONE&type=neq.EPIC&order=last_activity_at.desc"),
              ]);
              return {projects, open, inProgress, wontdo, done};
            },
            element: <KanbanBoardView/>,
          },
          {
            path: "backlog",
            loader: async function() {
              return APIService.getTickets("?and=(status.neq.DONE,status.neq.WONT_DO)&type=neq.EPIC&select=id,key,number,title,status,type,last_activity_at&order=last_activity_at.desc")
            },
            element: <BacklogBoardView/>,
          },
          {
            path: "tree",
            loader: async function() {
              return APIService.getProjects("?parent_id=is.null&order=key")
            },
            element: <TreeView/>,
          },
          {
            path: "calendar",
            element: <CalendarView/>
          },
        ]
      },
      {
        path: "/project",
        children: [
          {
            path: "list",
            loader: async ({params}) => {
              return APIService.getProjects("?select=key,name,last_activity_at,status&order=last_activity_at.desc");
            },
            element: <ProjectsView/>
          },
          {
            path: ":key",
            loader: async ({params}) => {
              return APIService.getProject("?key=eq." + params.key + "&select=*,projects_app_ticket(*),projects_app_repository(*)&projects_app_ticket.order=last_activity_at.desc");
            },
            element: <ProjectView/>,
          },
          {
            path: ":key/update",
            loader: async ({params}) => {
              const [project, projects] = await Promise.all([
                APIService.getProject("?key=eq." + params.key as string),
                APIService.getProjects(""),
              ]);
              return {project, projects};
            },
            element: <ProjectUpdateView/>
          },
          {
            path: "new",
            loader: async ({params}) => {
              return APIService.getProjects("")
            },
            element: <ProjectCreateView/>
          },
        ]
      },
      {
        path: "/repository",
        children: [
          {
            path: "list",
            loader: async ({params}) => {
              return APIService.getRepositories("?select=*,projects_app_project(*)&order=last_activity_at.desc");
            },
            element: <RepositoryListView/>
          },
          {
            path: "new",
            loader: async({params}) => {
              return APIService.getProjects("");
            },
            element: <RepositoryCreateView/>
          },
          {
            path: ":id",
            loader: async ({params}) => {
              const [repository, commits] = await Promise.all([
                APIService.getRepository("?id=eq." + params.id + "&select=*,projects_app_project(*)"),
                APIService.getCommits("?repository_id=eq." + params.id + "&order=timestamp.desc")
              ]);
              return {repository, commits}
            },
            element: <RepositoryView/>,
          },
          {
            path: ":id/update",
            loader: async ({params}) => {
              const [repository, projects] = await Promise.all([
                APIService.getRepository("?id=eq." + params.id),
                APIService.getProjects("")
              ]);
              return {repository, projects}
            },
            element: <RepositoryUpdateView/>
          }
        ]
      },
      {
        path: "/ticket",
        children: [
          {
            path: "list",
            loader: async ({params}) => {
              return APIService.getTickets("?order=created_at.desc");
            },
            element: <TicketListView/>
          },
          {
            path: "new",
            loader: async ({params}) => {
              const [projects, epics] = await Promise.all([
                APIService.getProjects(""),
                APIService.getTickets("?type=eq.EPIC&or=(status.eq.OPEN,status.eq.IN_PROGRESS)")
              ]);
              return {projects, epics};
            },
            element: <TicketCreateView/>
          },
          {
            path: ":number",
            loader: async ({params}) => {
              return APIService.getTicket(params.number as string);
            },
            element: <TicketView/>,
          },
          {
            path: ":number/update",
            loader: async ({params}) => {
              const [ticket, projects, epics] = await Promise.all([
                APIService.getTicket(params.number as string),
                APIService.getProjects(""),
                APIService.getTickets("?type=eq.EPIC&or=(status.eq.OPEN,status.eq.IN_PROGRESS)")
              ]);
              return {ticket, projects, epics};
            },
            element: <TicketUpdateView/>
          }
        ]
      }
    ]
  },
  {
    path: "/auth",
    children: [
      {
        path: "login",
        element: <Login/>
      },
      {
        path: "callback",
        element: <Callback/>
      }
    ]
  },
  {
    path: "*",
    element: <NotFound/>
  }
]);

function App() {
  return <RouterProvider router={router}/>
}

export default App;
