import { Link, useLoaderData, useRevalidator } from "react-router-dom"
import { Project, Ticket } from "../../model"
import { TicketLineList } from "../../components/lists/ticket";
import { SimpleList } from "../../components/lists/simple-list";
import { SimpleListItem } from "../../components/list-item";
import { RepositoryList } from "../../components/lists/repository";

export function ProjectView() {
    let project = useLoaderData() as Project;
    const revalidator = useRevalidator();

    function reload() {
        revalidator.revalidate();
    }

    return <div className="container p-2 mx-auto">
        <h1 className="text-2xl">{project.key} { project.name }</h1>

        <div className="my-5">
            <Link to={`/project/${project.key}/update`}> 
                <button className="px-4 py-2 bg-gray-100 rounded hover:bg-gray-300 dark:bg-slate-700 dark:hover:bg-slate-600">
                    <i className="bi bi-pencil"></i> Edit
                </button>
            </Link>
        </div>

        <div className="mt-5">
            <TicketLineList 
                title="Tickets" 
                tickets={project.projects_app_ticket as Ticket[]} 
                requestReload={() => reload() } 
                dropId={""}
            />
        </div>

        <div className="mt-5">
            { project.projects_app_repository ? <RepositoryList repositories={ project.projects_app_repository }/> : null }
        </div>

        <div className="mt-5">
            <SimpleList title="Commits">
                { project.projects_app_commit?.map(commit => {
                    let message = commit.message.split("\n");

                    return <Link to={commit.url}>
                        <SimpleListItem>
                            <div className="font-mono">{commit.hash.substring(0, 6)}</div>
                            <div className="ml-5">{message[0]}</div>
                        </SimpleListItem>
                    </Link>
                })}
            </SimpleList>
        </div>
    </div>
}