import { SimpleList } from "./lists/simple-list"
import { Commit } from "../model"
import { Link } from "react-router-dom"
import { SimpleListItem } from "./list-item"
import moment from "moment"

export function CommitList(props: CommitListProps) {
    return <SimpleList title="Commits">
        {props.commits.map(commit => {
            let message = commit.message.split("\n");

            return <Link to={commit.url} key={commit.hash}>
                <SimpleListItem>
                    <div className="font-mono">{commit.hash.substring(0, 6)}</div>
                    <div className="ml-4">{moment(commit.timestamp).fromNow()}</div>
                    <div className="ml-5">{message[0]}</div>
                </SimpleListItem>
            </Link>
        })}
    </SimpleList>
}

export interface CommitListProps {
    commits: Commit[];
}