import { RepositoryProvider, TicketStatus, TicketType } from "../model";

export function getTicketStatusPill(status: TicketStatus) {
    let colors = "";
    let text = "";

    switch(status) {
        case "OPEN":
            colors = "bg-blue-200 dark:bg-gray-500";
            text = "Open";
            break;
        case "IN_PROGRESS":
            colors = "bg-blue-200 dark:bg-blue-800";
            text = "In Progress";
            break;
        case "WONT_DO":
            colors = "bg-red-200 dark:bg-red-800";
            text = "Won't do";
            break;
        case "DONE":
            colors = "bg-green-200 dark:bg-green-800";
            text = "Done";
            break;
    }

    return <span className={`px-1 rounded ${colors}`}>{ text }</span>
}

export function getTicketTypeIcon(type: TicketType) {
    let style = "";

    switch (type) {
        case "EPIC":
            style = "bg-purple-400 bi bi-lightning-fill dark:bg-purple-900";
            break;
        case "STORY":
            style = "bg-green-400 bi bi-bookmark-fill dark:bg-green-900";
            break;
        case "RESEARCH":
            style = "bg-green-400 bi bi-search dark:bg-blue-900";
            break;
        case "BUG":
            style = "bg-red-400 bi bi-circle-fill dark:bg-red-900";
            break;
    }

    return <div className={`inline p-1 text-[10px] align-[2px] rounded text-white ${style}`}/>;
}

export function getProviderIcon(provider: RepositoryProvider) {
    switch (provider) {
        case "GITHUB":
            return <i className="bi bi-github"></i>
    }
}