import { SubmitHandler, useForm } from "react-hook-form";
import { Project } from "../../model";

export function ProjectForm(props: ProjectFormProps) {
    const {register, handleSubmit, formState: { errors },} = useForm<Project>({
        defaultValues: props.existing || {}
    })

    const onSubmit: SubmitHandler<Project> = (project: Project) => {

        // @ts-ignore
        if (project.parent_id === "-1") {
            project.parent_id = null;
        }
        
        props.onSubmit(project);
    };

    return (
        <div className="container p-2 mx-auto">
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="key">Key</label>
                    <div className="flex-1">
                        <input id="key" {...register("key", { required: true })} className="inline-block w-full p-2 bg-gray-100 border-2 border-gray-300 rounded dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-600" />
                        {errors.name && <span>This field is required</span>}
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="name">Name</label>
                    <div className="flex-1">
                        <input id="name" {...register("name", { required: true })} className="inline-block w-full p-2 bg-gray-100 border-2 border-gray-300 rounded dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-600" />
                        {errors.name && <span>This field is required</span>}
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <div className="w-2/12"></div>
                    <button type="submit" className="p-2 text-white bg-blue-600 rounded">Save</button>
                </div>
            </form>
        </div>
    );
}

export interface ProjectFormProps {
    existing?: Project;
    projects: Project[];
    onSubmit: (project: Project) => void;
}