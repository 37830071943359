import { Link, useLoaderData, useNavigate } from "react-router-dom"
import { Ticket } from "../../model";
import { TicketLineList } from "../../components/lists/ticket";
import PageTitle from "../../components/page-title";
import { Container } from "../../components/container";
import { Button } from "@joushx/react-components";

export function TicketListView() {
    const tickets: Ticket[] = useLoaderData() as Ticket[];
    const navigate = useNavigate();

    function reload() {
        navigate(".", {replace: true});
    }

    return <Container>

        <PageTitle title="Tickets"/>

        <div className="mb-5">
            <Link to="/ticket/new">
                <Button>Create Ticket</Button>
            </Link>
        </div>

        <TicketLineList title="Tickets" tickets={tickets} requestReload={() => reload()} dropId=""/>
    </Container>
}