import { Link } from "react-router-dom";

export function NotFound() {
    return <div className="flex items-center justify-center h-screen">
        <div className="">
            <div className="text-9xl">404</div>
            <div className="mt-5 text-2xl">Cannot find page</div>

            <Link to="/">
                <button className="p-2 mt-5 text-white bg-blue-500 rounded-sm hover:bg-blue-600">
                    Go back to Dashboard
                </button>
            </Link>
        </div>
    </div>
}