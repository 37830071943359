import { APIService } from "../../services/api"
import { useLoaderData, useNavigate } from "react-router-dom";
import { RepositoryForm } from "../../components/forms/repository-form";
import { toast } from "react-toastify";

export function RepositoryUpdateView() {
    const {repository, projects} = useLoaderData() as any;
    const navigate = useNavigate();

    return <RepositoryForm projects={projects} existing={repository} onSubmit={repository => {
        APIService.updateRepository(repository.id, repository).then(() => {
            toast.info(`Repository "${ repository.name } updated"`);
            navigate("/repository/" + repository.id);
        }).catch(e => {
            toast.error(e);
        })
    }}/>
}