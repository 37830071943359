import { Link, useLoaderData } from "react-router-dom"
import { Project } from "../../model"
import { SimpleList } from "../../components/lists/simple-list";
import { SimpleListItem } from "../../components/list-item";
import moment from "moment";
import { Container } from "../../components/container";
import { Button } from "@joushx/react-components";

export function ProjectsView() {
    let projects = useLoaderData() as Project[];

    return <Container>
        <div className="mb-5">
            <Link to="/project/new">
                <Button>Create Project</Button>
            </Link>
        </div>

        <SimpleList title="Projects">
            { projects.map(project => {
                return <Link to={`/project/${project.key}`}>
                    <SimpleListItem key={project.id}>
                        <div className="inline text-gray-600 dark:text-gray-400">
                            { project.key }
                        </div>

                        <div className="flex-1 inline ml-3">
                            { project.name }
                        </div>

                        <div className="hidden mx:inline ml-3">
                            { moment(project.last_activity_at).fromNow()}
                        </div>

                        <div className="inline ml-3">
                            { project.status }
                        </div>
                    </SimpleListItem>
                </Link>
            }) }
        </SimpleList>
    </Container>
}