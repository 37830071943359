import { useDroppable } from "@dnd-kit/core";
import { TicketCard } from "../preview"
import { Ticket, TicketStatus } from "../../model";
import { TicketPreviewLine } from "../line";
import { Link } from "react-router-dom";

export function TicketList(props: any) {
    return (
        <div className="flex-1 h-full">
            <div className={`h-full p-2 bg-gray-100 rounded dark:bg-zinc-900`}>
                <div className="p-2 font-semibold text-gray-500 uppercase md:p-3 dark:text-gray-200">{ props.title }</div>
                <div>
                    { props.children }
                </div>
            </div>
        </div>
    )
}

export function TicketCardList(props: TicketCardListProps) {

    const {isOver, setNodeRef} = useDroppable({
        id: props.id,
        data: {
            newStatus: props.dropStatus
        }
    });

    return (
        <div ref={setNodeRef} className={`w-full h-full ${isOver ? "ring-1 dark:ring-white ring-red" : ""}`}>
            <TicketList title={`${props.title} (${props.tickets.length})`}>
                { props.tickets.map((ticket: Ticket) => {
                    return <Link to={`/ticket/${ticket.key}-${ticket.number}`} key={ticket.id}>
                        <TicketCard ticket={ticket}/>
                    </Link>
                })}
            </TicketList>
        </div>
    )
}

export interface TicketCardListProps {
    title: string;
    tickets: Ticket[];
    id: string;
    dropStatus: TicketStatus;
}

export function TicketLineList(props: TicketListProps) {

    const {isOver, setNodeRef} = useDroppable({
        id: props.dropId
    });

    return (
        <div ref={setNodeRef} className={`w-full ${isOver ? "ring-1 ring-white" : ""}`}>
            <TicketList title={props.title}>
                { props.tickets.map((ticket: Ticket) => {
                    return <Link to={`/ticket/${ticket.key}-${ticket.number}`} key={ticket.id}>
                            <TicketPreviewLine ticket={ticket} showStatus={true} requestReload={props.requestReload}/>
                    </Link>
                  })}
            </TicketList>
        </div>
    )
}

export interface TicketListProps {
    title: string;
    dropId: string;
    tickets: Ticket[];
    requestReload: () => void;
}