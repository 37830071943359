import { Link, Outlet, matchPath, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Navbar, NavbarBody, NavbarItem, NavbarSuffix } from "@joushx/react-components";
import { Search } from "../../components/search";
import { useState, useEffect } from "react";
import { AuthService } from "../../services/auth";

export function Layout(props: any) {

    const { pathname } = useLocation();
    const [userInfo, setUserInfo] = useState<any>(null);

    useEffect(() => {
        let claims = AuthService.getClaims(AuthService.getToken());
        setUserInfo(claims);
    }, []);

    let menu: any[] = [
        {
            title: "Boards",
            path: "/boards/kanban"
        },
        {
            title: "Projects",
            path: "/project/list"
        },
        {
            title: "Tickets",
            path: "/ticket/list"
        },
        {
            title: "Repositories",
            path: "/repository/list"
        }
    ]

    const navigate = useNavigate();

    return <>
        <ToastContainer autoClose={3000} theme="colored" />
        <div className="h-full flex flex-col">

            <Navbar title="Projects" onTitleClick={() => navigate("/")} icon="/icon-192.png">
                { menu.map(menuItem => {
                    let isActive = !!matchPath(menuItem.path, pathname)
                    return <NavbarItem title={menuItem.title} onClick={() => navigate(menuItem.path)} active={isActive} key={menuItem.path} />
                })}
                <NavbarBody>
                    <Link to="/ticket/new">
                        <Button variant="primary">Create</Button>
                    </Link>
                </NavbarBody>
                <NavbarSuffix>
                    <div className="flex-1">
                        <Search/>
                    </div>

                    <div className="ml-5">
                        <div title="Settings" className="inline-block align-middle">
                            <i className="bi bi-gear"></i>
                        </div>
                        <div title={`${userInfo?.name} (${userInfo?.sub})`} className="ml-3 inline-block align-middle">
                            <i className="bi bi-person-circle"></i>
                        </div>
                    </div>
                </NavbarSuffix>
            </Navbar>

            <div className="flex-1">
                <Outlet/>
            </div>
        </div>
    </>
}