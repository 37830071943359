import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ContainerFluid } from '../../components/container';

// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

export function CalendarView() {
    return <ContainerFluid>
        <Calendar
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        />
    </ContainerFluid>
}