import { SimpleList } from "./simple-list"
import { Repository } from "../../model"
import { Link } from "react-router-dom"
import { SimpleListItem } from "../list-item"
import { getProviderIcon } from "../../utils/ui"

export function RepositoryList(props: RepositoryProps) {
    return <SimpleList title="Repositories">
        { props.repositories.map((repository: Repository) => {
            return <Link to={`/repository/${repository.id}`}>
                <SimpleListItem key={repository.id}>
                    <div className="w-8">
                        { getProviderIcon(repository.provider) }
                    </div>
                    <div className="w-64">
                        { repository.name }
                    </div>
                    {repository.projects_app_project ? <div className="w-32">
                        { repository.projects_app_project.key }
                    </div> : null }
                    <div className="flex-1">
                        { repository.description !== "None" ? repository.description : null }
                    </div>
                </SimpleListItem>
            </Link>
        })}
    </SimpleList>
}

export interface RepositoryProps {
    repositories: Repository[];
}