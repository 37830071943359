import { ChangeEvent, useState } from "react";
import { APIService } from "../services/api";
import { Ticket } from "../model";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getTicketTypeIcon } from "../utils/ui";

export function Search() {
    const [results, setResults] = useState<Ticket[]>([]);
    const navigate = useNavigate();

    function inputChanged(e: ChangeEvent<HTMLInputElement>) {
        let value = e.target.value;

        if (value.length < 3) {
            setResults([]);
            return;
        }

        APIService.search(value).then(tickets => {
            setResults(tickets);
        })
    }

    return <>
        <form>   
            <label htmlFor="default-search" className="mb-2 text-sm font-medium text-white sr-only dark:text-white">Search</label>
            <div className="relative">
                <input
                    onBlur={e =>setResults([])}
                    onChange={inputChanged} 
                    type="search"
                    className="pl-5 focus:text-black focus:w-[600px] focus:bg-white w-96 block w-full px-2 py-1 ps-10 text-sm text-white border border-gray-300 rounded bg-transparent focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                    placeholder="Search"
                    required
                />
            </div>
        </form>

        { results.length > 0 ? <div className="absolute dark:bg-neutral-700 bg-white dark:text-white text-black shadow w-[600px] z-50">
            { results.map((ticket, index) => {
                return <div className={`flex p-3 cursor-pointer hover:bg-gray-200 border-gray-200 dark:border-neutral-500 dark:hover:bg-neutral-600 ${ index > 0 ? "border-t" : ""}`} onMouseDown={e => {
                    setResults([]);
                    navigate(`/ticket/${ticket.key}-${ticket.number}`);
                }} key={ticket.id}>
                    <div className="py-2 pr-2">
                        { getTicketTypeIcon(ticket.type) }
                    </div>
                    <div>
                        <div className="text-sm">{ticket.key} {ticket.title}</div>
                        <div className="text-gray-400 text-xs">{ticket.projects_app_project?.name} - Updated { moment(ticket.updated_at).fromNow()}</div>
                    </div>
                </div>
            })}
        </div> : null}
    </>
    
}