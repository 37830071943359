import { Link, useLoaderData, useRevalidator } from "react-router-dom"
import { Ticket } from "../../model";
import Markdown from "react-markdown";
import { StatusDropdown } from "../../components/status-dropdown";
import { APIService } from "../../services/api";
import { SidebarDetails } from "../../components/sidebar-details";
import moment from "moment";
import { getTicketTypeIcon } from "../../utils/ui";
import { CommitList } from "../../components/commit-list";
import PageTitle from "../../components/page-title";
import { Container } from "../../components/container";
import { toast } from "react-toastify";
import { Button } from "@joushx/react-components";

export function TicketView() {
    const ticket = useLoaderData() as Ticket;
    const revalidator = useRevalidator();

    return (
        <Container>
            <div className="md:flex">
                <PageTitle title={`${ticket.key}-${ticket.number} ${ticket.title}`}/>

                <div id="main" className="flex-1">
                    <div className="flex">
                        <div className="flex-1">
                            <div className="text-xl">
                                <span>Projects</span>

                                {ticket.projects_app_project &&
                                    <>
                                        <span className="mx-2">/</span>
                                        <Link to={`/project/${ticket.projects_app_project.key}`}>
                                            <span>{ticket.projects_app_project.key}</span>
                                        </Link>
                                    </>
                                }

                                {ticket.projects_app_ticket && ticket.projects_app_ticket.length > 0 &&
                                    <>
                                        <span className="mx-2">/</span>
                                        <Link to={`/ticket/${ticket.projects_app_ticket[0].key}-${ticket.projects_app_ticket[0].number}`}>
                                            <span>{getTicketTypeIcon(ticket.projects_app_ticket[0].type)} {ticket.projects_app_ticket[0].key}-{ticket.projects_app_ticket[0].number}</span>
                                        </Link>
                                    </>
                                }

                                <span className="mx-2">/</span>
                                <span>{getTicketTypeIcon(ticket.type)} {ticket.key}-{ticket.number}</span>
                            </div>
                            <h1 className="mt-5 text-3xl font-semibold">{ticket.title}</h1>
                        </div>
                    </div>

                    <div className="my-5">
                        <Link to={`/ticket/${ticket.key}-${ticket.number}/update`}>
                            <Button>
                                <i className="bi bi-pencil"></i> Edit
                            </Button>
                        </Link>
                    </div>

                    <h2 className="mt-5 text-lg font-semibold">Description</h2>

                    <div className="my-5 markdown">
                        <Markdown>{ticket.description}</Markdown>
                    </div>

                    {/*<h2 className="mt-20 text-lg font-semibold">Child Issues</h2>

                    <div className="my-5">

                        <Button>
                            New Ticket
                        </Button>

                    </div>

                    <div>

                        <div className="p-2 bg-gray-100 rounded md:p-5 dark:bg-slate-700">

                            <div>

                                <div className="text-center">No tickets found</div>

                            </div>
                        </div>


                    </div>

                    <h2 className="mt-20 text-lg font-semibold">Linked Issues</h2>*/}

                    { ticket.projects_app_commit ? <CommitList commits={ticket.projects_app_commit} /> : null }
                </div>
                <div className="mt-10 md:mt-0 md:w-4/12 md:pl-5" id="sidebar">

                    <StatusDropdown status={ticket.status} onStatusChange={(status) => {
                        APIService.updateTicket(ticket.id, {
                            "status": status
                        }).then(() => {
                            toast.info(`Status changed`);
                            revalidator.revalidate();
                        })
                    }} />

                    <div className="mt-5">
                        <SidebarDetails title="Details">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="p-2 font-semibold">
                                            Labels
                                        </td>
                                        <td className="pl-10">

                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="p-2 font-semibold">
                                            Development
                                        </td>
                                        <td className="pl-10">
                                            { ticket.projects_app_commit ? <div>{ ticket.projects_app_commit?.length } commits</div> : null }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </SidebarDetails>
                    </div>

                    <div className="mt-5 text-gray-400">
                        <div title={ticket.created_at.toString()}>Created {moment(ticket.created_at).fromNow()}</div>
                        <div title={ticket.updated_at.toString()}>Updated {moment(ticket.updated_at).fromNow()}</div>
                    </div>
                </div>
            </div>
        </Container>
    );
}