import { Project, Ticket } from "../../model";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { TicketCard } from "../../components/preview";
import { DndContext, DragEndEvent, DragOverlay, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { useState } from "react";
import { TicketCardList } from "../../components/lists/ticket";
import { APIService } from "../../services/api";
import { ExpandingSection } from "../../components/expanding-section";
import PageTitle from "../../components/page-title";
import { ContainerFluid } from "../../components/container";
import { toast } from 'react-toastify';
import { Button } from "@joushx/react-components";

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

export function KanbanBoardView() {
    const {projects, open, inProgress, wontdo, done} = useLoaderData() as any;
    const [activeTicket, setActiveTicket] = useState<Ticket | null>(null);
    const [grouped, setGrouped] = useState<boolean>(false);
    const revalidator = useRevalidator();

    function groupByProjectKey(
        open: Ticket[],
        inProgress: Ticket[], 
        wontdo: Ticket[],
        done: Ticket[]
    ): any {
        let projects: any = {};
    
        function addTicket(ticket: Ticket, status: string) {
            if (!(ticket.key in projects)) {
                projects[ticket.key] = {
                    open: [],
                    inProgress: [],
                    wontdo: [],
                    done: []
                };
            }
            projects[ticket.key][status].push(ticket);
        }
    
        open.forEach(ticket => addTicket(ticket, 'open'));
        inProgress.forEach(ticket => addTicket(ticket, 'inProgress'));
        wontdo.forEach(ticket => addTicket(ticket, 'wontdo'));
        done.forEach(ticket => addTicket(ticket, 'done'));
    
        return projects;
    }

    const groupedByProjects = groupByProjectKey(open, inProgress, wontdo, done);

    function onDragStart(e: DragEndEvent) {
        let allTickets = open.concat(inProgress).concat(wontdo).concat(done);
        let activeTicket = allTickets.find((t: Ticket) => t.id === e.active.id);
        setActiveTicket(activeTicket);
    }

    function onDragEnd(e: DragEndEvent) {

        if(!e.over) {
            return;
        }

        if(!e.over.data.current) {
            return;
        }

        APIService.updateTicket(e.active.id as number, {
            "status": e.over.data.current.newStatus
        }).then(() => {
            revalidator.revalidate();
            toast.info(`${activeTicket?.key}-${activeTicket?.number}: ${activeTicket?.status} → ${e.over?.data.current ? e.over?.data.current.newStatus : ""}`, {});
            setActiveTicket(null);
        }).catch((e: Error) => {
            toast.error(e.message);
        })
    }

    // prevent dragging to activate on click
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 8,
            },
        })
    )

    let content = grouped ? 
            Object.keys(groupedByProjects).sort().map((project, index) => {
                let projectObject = projects.find((p: Project) => p.key === project);
                let title = `${projectObject?.key} - ${projectObject?.name}`

                return <ExpandingSection key={project} title={title} initiallyOpen={index === 0}>
                    <div className="md:flex">
                        <div className="flex-1 mt-3">
                            <TicketCardList title="Open" tickets={groupedByProjects[project].open} id={"open-" + project} dropStatus="OPEN" />
                        </div>
                        <div className="flex-1 mt-3 md:ml-5">
                            <TicketCardList title="In Progress" tickets={groupedByProjects[project].inProgress} id={"inprogress-" + project} dropStatus="IN_PROGRESS"/>
                        </div>
                        <div className="flex-1 mt-3 md:ml-5">
                            <TicketCardList title="Won't do" tickets={groupedByProjects[project].wontdo.slice(0,5)} id={"wontdo-" + project} dropStatus="WONT_DO"/>
                        </div>
                        <div className="flex-1 mt-3 md:ml-5">
                            <TicketCardList title="Done" tickets={groupedByProjects[project].done.slice(0,5)} id={"done-" + project} dropStatus="DONE"/>
                        </div>
                    </div>
                </ExpandingSection>
            })
        : 
            <div className="md:flex">
                <div className="flex-1 mt-3">
                    <TicketCardList title="Open" tickets={open} id="open" dropStatus="OPEN" />
                </div>
                <div className="flex-1 mt-3 md:ml-5">
                    <TicketCardList title="In Progress" tickets={inProgress} id="inprogress" dropStatus="IN_PROGRESS" />
                </div>
                <div className="flex-1 mt-3 md:ml-5">
                    <TicketCardList title="Won't do" tickets={wontdo.slice(0,20)} id="wontdo" dropStatus="WONT_DO" />
                </div>
                <div className="flex-1 mt-3 md:ml-5">
                    <TicketCardList title="Done" tickets={done.slice(0,20)} id="done" dropStatus="DONE" />
                </div>
            </div>

    return <ContainerFluid>
        <div className="w-full text-right">
            <Button onClick={() => setGrouped(!grouped)}>{ grouped ? "Show unified" : "Group by project"}</Button>
        </div>

        <PageTitle title="Kanban Board"/>

        { isMobile ? 
            content
            :
            <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd} sensors={sensors}>
                { content }

                <DragOverlay dropAnimation={null}>
                    { activeTicket && <TicketCard ticket={activeTicket}/> }
                </DragOverlay>
            </DndContext>
        }

        
    </ContainerFluid>
}