import { Ticket } from "../model";
import { getTicketTypeIcon } from "../utils/ui";
import { StatusDropdown } from "./status-dropdown";
import { APIService } from "../services/api";
import { SimpleListItem } from "./list-item";

export function TicketPreviewLine(props: TicketLineProps) {
    return <SimpleListItem>
        <div className="w-27">
            { getTicketTypeIcon(props.ticket.type) }

            <div className={`inline ml-2 text-gray-600 ${props.ticket.status === "DONE" || props.ticket.status === "WONT_DO" ? "line-through" : ""} dark:text-gray-400`}>
                {props.ticket.key}-{ props.ticket.number}
            </div>
        </div>

        <div className="flex-1 truncate">
            <div className={`inline mx-3 ${props.ticket.status === "DONE" || props.ticket.status === "WONT_DO" ? "line-through" : ""}`}>
                { props.ticket.title }
            </div>
        </div>
        
        { props.showStatus && <div className="hidden ml-3 text-right whitespace-nowrap md:inline-block">
            <StatusDropdown status={props.ticket.status} variant="SMALL" onStatusChange={(status) => {
                APIService.updateTicket(props.ticket.id, {
                    "status": status
                }).then(() => props.requestReload())
            }}/>
        </div>}
    </SimpleListItem>;
}

interface TicketLineProps {
    ticket: Ticket;
    showStatus?: boolean;
    requestReload: () => void;
}