import { PropsWithChildren, useState } from "react";

export function ExpandingSection(props: ExpandingSectionProps) {
    const [open, setOpen] = useState(props.initiallyOpen);

    return <div className={`mb-2 ${!open ? "border-b dark:border-slate-600 border-gray-100 hover:bg-gray-100 dark:hover:bg-slate-600" : ""}`}>
        <div className="flex" onClick={() => setOpen(!open)}>
            <div className="flex-1 text-xl cursor-pointer dark:text-slate-200 text-slate-500">{ props.title }</div>
            <div>
                { open ?
                    <button><i className="m-4 bi bi-chevron-up"></i></button>
                    : 
                    <button><i className="m-4 bi bi-chevron-down"></i></button>
                }
            </div>
        </div>

        <div className="mb-2">
        { open ? props.children : null }
        </div>
    </div>
}

export interface ExpandingSectionProps extends PropsWithChildren {
    title: string;
    initiallyOpen: boolean;
}