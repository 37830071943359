import { PropsWithChildren } from "react";

export function Container(props: PropsWithChildren) {
    return <div className="container p-1 md:p-2 mx-auto md:p-0 my-3 md:my-5">
        { props.children }
    </div>
}

export function ContainerFluid(props: PropsWithChildren) {
    return <div className="mx-1 md:mx-3 md:mx-10 h-full">
        { props.children }
    </div>
}