import { APIService } from "../../services/api"
import { useLoaderData, useNavigate } from "react-router-dom";
import { Project } from "../../model";
import { ProjectForm } from "../../components/forms/project-form";
import { toast } from "react-toastify";

export function ProjectCreateView() {
    const projects = useLoaderData() as Project[];
    const navigate = useNavigate();

    return <ProjectForm projects={projects} onSubmit={project => {
        APIService.createProject(project).then(project => {
            toast.info(`Project "${ project.name } created"`);
            navigate("/project/" + project.key);
        })
        .catch((e: Error) => {
            toast.error(e.message);
        })
    }}/>
}