import { NavLink, Outlet } from "react-router-dom";

const menuItems = [
    {
        "title": "Kanban board",
        "icon": "bi bi-kanban",
        "path": "/boards/kanban"
    },
    {
        "title": "Backlog",
        "icon": "bi bi-list-columns-reverse",
        "path": "/boards/backlog"
    },
    {
        "title": "Tree",
        "icon": "bi bi-list-nested",
        "path": "/boards/tree"
    },
    {
        "title": "Calendar",
        "icon": "bi bi-calendar-week",
        "path": "/boards/calendar"
    }
]

export function BoardsView() {

    return <div className="w-full md:flex h-full">
        <div className="md:w-56 md:border-r-2 dark:border-slate-700 border-gray-200 md:h-full md:mt-3">
            { menuItems.map(item => {
                return <NavLink to={item.path} key={item.title} className={({ isActive }) =>
                    isActive ? "font-semibold" : ""
                  }>
                    <div className="p-3 mx-3 dark:hover:bg-slate-700 hover:bg-blue-200 dark:text-white rounded">
                        <i className={`${item.icon} mr-2`}></i> 
                        {item.title}
                    </div>
                </NavLink>
            })}
        </div>
        <div className="flex-1 mt-10 md:mt-5 h-full overflow-y-auto">
            <Outlet/>
        </div>
    </div>
}