import { useState } from "react";
import { TicketStatus } from "../model";
import { getTicketStatusPill } from "../utils/ui";

export function StatusDropdown(props: StatusDropdownProps) {

    const [menuOpen, setMenuOpen] = useState(false);

    let style = "";
    let text = "";
    switch(props.status) {
        case "OPEN":
            style = "bg-zinc-200 text-slate-600";
            text = "Open";
            break;
        case "IN_PROGRESS":
            style = "bg-blue-100 text-blue-700";
            text = "In Progress"
            break;
        case "DONE":
            style = "bg-green-100 text-emerald-800";
            text = "Done";
            break;
        case "WONT_DO":
            style = "bg-red-100 text-red-700";
            text = "Won't do";
            break;
    }

    function onSelect(status: TicketStatus) {
        if(!props.onStatusChange) {
            return
        }

        props.onStatusChange(status);
        setMenuOpen(false);
    }

    return <div>
        <button className={`${props.variant === "SMALL" ? "px-1" : "px-3 py-2"} font-semibold rounded ${style}`} onClick={e => {
            e.preventDefault();
            setMenuOpen(!menuOpen);
        }}>
            { text } <i className="bi bi-chevron-down"></i>
        </button>

        { menuOpen && 
            <div className="z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow absolute" id="dropdown">
                <ul className="py-1" aria-labelledby="dropdown">
                    { (["OPEN", "IN_PROGRESS", "DONE", "WONT_DO"] as TicketStatus[]).map(status => {
                        return <li onClick={e => {
                            e.preventDefault();
                            onSelect(status);
                        }} key={status}>
                            <div className="block px-4 py-2 text-sm cursor-pointer hover:bg-gray-100" border-b-2 border-gray-200>{ getTicketStatusPill(status) }</div>
                        </li>
                    })}
                </ul>
            </div>
        }
    </div>
}

export interface StatusDropdownProps {
    status: TicketStatus;
    variant?: "NORMAL" | "SMALL";
    onStatusChange?: (newStatus: TicketStatus) => void;
}