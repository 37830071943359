import { SubmitHandler, useForm } from "react-hook-form";
import { Project, Ticket } from "../../model";
import Markdown from "react-markdown";

export function TicketForm(props: TicketFormProps) {
    const {register, handleSubmit, watch, formState: { errors },} = useForm<Ticket>({
        defaultValues: props.existing || {}
    })

    const onSubmit: SubmitHandler<Ticket> = (ticket: Ticket) => {
        if (!ticket.status) {
            ticket.status = "OPEN"
        }

        // @ts-ignore
        if (ticket.parent_id === "-1") {
            ticket.parent_id = null;
        }

        delete ticket.projects_app_commit;
        delete ticket.projects_app_project;
        delete ticket.projects_app_ticket;
        
        props.onSubmit(ticket);
    };

    return (
        <div className="container p-2 mx-auto">
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="project">Project</label>
                    <div className="flex-1">
                        <select id="project" {...register("project_id", { required: true })} className="inline-block w-full p-2 bg-gray-100 border-2 border-gray-300 rounded dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-600">
                            { props.projects.map(project => {
                                return <option value={project.id}>{project.key} - {project.name}</option>
                            })}
                        </select>
                        {errors.project_id && <span>This field is required</span>}
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="epic">Epic</label>
                    <div className="flex-1">
                        <select id="epic" {...register("parent_id", { })} className="inline-block w-full p-2 bg-gray-100 border-2 border-gray-300 rounded dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-600">
                            <option value={"-1"}>---</option>
                            { props.epics.map((epic: Ticket) => {
                                return <option value={epic.id}>{epic.key}-{epic.number} {epic.title}</option>
                            })}
                        </select>
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="type">Type</label>
                    <div className="flex-1">
                        <select id="type" {...register("type", { required: true })} className="inline-block w-full p-2 bg-gray-100 border-2 border-gray-300 rounded dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-600">
                            <option value="STORY">Story</option>
                            <option value="BUG">Bug</option>
                            <option value="EPIC">Epic</option>
                            <option value="RESEARCH">Research</option>
                        </select>
                        {errors.type && <span>This field is required</span>}
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="status">Status</label>
                    <div className="flex-1">
                        <select id="status" {...register("status", { required: true })} className="inline-block w-full p-2 bg-gray-100 border-2 border-gray-300 rounded dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-600">
                            <option value="OPEN">Open</option>
                            <option value="IN_PROGRESS">In Progress</option>
                            <option value="DONE">Done</option>
                            <option value="WONT_DO">Won't do</option>
                        </select>
                        {errors.status && <span>This field is required</span>}
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="title">Title</label>
                    <div className="flex-1">
                        <input id="title" {...register("title", { required: true })} className="inline-block w-full p-2 bg-gray-100 border-2 border-gray-300 rounded dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-600" />
                        {errors.title && <span>This field is required</span>}
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="description">Description</label>
                    <div className="flex-1">
                        <textarea id="description" {...register("description", {  })} className="inline-block w-full p-2 bg-gray-100 border-2 border-gray-300 rounded dark:border-slate-600 dark:bg-slate-700 dark:hover:bg-slate-600" />
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <label className="w-2/12 py-2 pr-5 text-right text-gray-700 dark:text-white" htmlFor="description">Preview</label>
                    <div className="flex-1 markdown">
                        <Markdown>
                            { watch("description") }
                        </Markdown>
                    </div>
                </div>

                <div className="block my-5 md:flex">
                    <div className="w-2/12"></div>
                    <button type="submit" className="p-2 text-white bg-blue-600 rounded">Save</button>
                </div>
            </form>
        </div>
    );
}

export interface TicketFormProps {
    existing?: Ticket;
    projects: Project[];
    epics: Ticket[];
    onSubmit: (ticket: Ticket) => void;
}