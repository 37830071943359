import { PropsWithChildren } from "react";

export function SidebarDetails(props: SidebarDetailsProps) {
    return <div className="border-2 border-gray-200 rounded-lg dark:border-gray-600">
        <div className="p-3 text-lg font-semibold border-b-2 border-gray-200 dark:border-gray-600">{ props.title }</div>
        <div className="p-3">
            { props.children }
        </div>
    </div>
}

export interface SidebarDetailsProps extends PropsWithChildren {
    title: string;
}