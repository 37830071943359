import { APIService } from "../../services/api"
import { useLoaderData, useNavigate } from "react-router-dom";
import { TicketForm } from "../../components/forms/ticket-form";
import { toast } from "react-toastify";

export function TicketCreateView() {
    // @ts-ignore
    const {projects, epics} = useLoaderData();
    const navigate = useNavigate();

    return <TicketForm projects={projects} epics={epics} onSubmit={ticket => {
        APIService.createTicket(ticket).then(ticket => {
            toast.info(`Ticket "${ticket.key}-${ticket.number} ${ ticket.title }" created`);
            navigate("/ticket/" + ticket.key + "-" + ticket.number);
        })
        .catch(e => {
            toast.error(e);
        });
    }}/>
}