import { Button } from "@joushx/react-components";
import { AuthService } from "../../services/auth";

export function Login() {
    function startLogin() {
        let url = AuthService.getLoginURL();
        window.location.href = url;
    }

    return <div className="h-screen bg-slate-300 dark:bg-slate-800 lg:flex">

        <div className="h-screen md:flex min-h-[300px] lg:h-[66%] lg:m-auto lg:w-[66%] bg-white shadow rounded-sm">

            {/* login form */}
            <div className="flex flex-col h-screen md:w-[20%] md:min-w-[300px] lg:h-full">
                <div className="block p-5">
                    <img className="inline mr-1 align-[-8px] w-8" src="/icon-192.png" alt="Logo"/>
                    <div className="inline uppercase text-2xl">Projects</div>
                </div>
                <div className="flex-1 text-black">
                    <div className="m-auto p-5">
                        <div className="text-4xl mt-10 font-light">Welcome</div>

                        <p className="mt-3 text-gray-600">If you have an account, please sign in with the button below</p>

                        <div className="mt-5">
                            <Button variant="primary" expanding={true} onClick={() => startLogin()}>Sign In with SSO</Button>
                        </div>
                    </div>
                </div>
            </div>

            {/* big image */}
            <div className="flex-1 hidden bg-center bg-cover md:block" style={{backgroundImage: "url(/hero.webp)"}}/>
        </div>
    </div>
}