const STORAGE_KEY = "accessToken";
const CLIENT_ID = "7Qwwv2AZTJNfgXxsFKir4TKbCWfOH7UU2CzlvHIF";
const REDIRECT_URI = process.env.REACT_APP_BASE_URL + "/#/auth/callback#";

export class AuthService {
    public static isAuthenticated() {
        let token = this.getToken();

        if (!token) {
            return false;
        }

        let claims = this.getClaims(token)
        if (claims.exp < Math.floor(Date.now() / 1000)) {
            return false;
        }

        return true;
    }

    public static getLoginURL() {
        let params = new URLSearchParams();
        params.set("client_id", CLIENT_ID);
        params.set("scope", "openid postgrest profile");
        params.set("response_type", "code token")
        params.set("redirect_uri", REDIRECT_URI);

        return "https://auth.jmittendorfer.at/application/o/authorize/?" + params.toString()
    }

    public static getClaims(token: string): any {
        let token_parts = token.split(".");
        if (token_parts.length !== 3) {
            return false;
        }

        return JSON.parse(atob(token_parts[1]));
    }

    public static setToken(token: string) {
        localStorage.setItem(STORAGE_KEY, token);
    }

    public static getToken(): string {
        return localStorage.getItem(STORAGE_KEY) as string;
    }
}