import { useNavigate } from "react-router-dom";
import { AuthService } from "../../services/auth";
import { useState } from "react";
import React from "react";

export function Callback() {
    const navigate = useNavigate();
    const [params, setParams] = useState<URLSearchParams>();

    React.useEffect(() => {
        let hash_parts = window.location.hash.split("#");
        if (hash_parts.length !== 3) {
            return;
        }

        let params = new URLSearchParams(hash_parts[2]);

        if (params.has("access_token")) {
            AuthService.setToken(params.get("access_token") as string);
            navigate("/");
        }

        setParams(params);        
    }, [navigate]);

    if (params && params.has("error")) {
        return <div>
            <b>{ params.get("error") }: </b>

            { params.has("error_description") && params.get("error_description") }
        </div>
    }

    return <div>?</div>
}