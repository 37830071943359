import { APIService } from "../../services/api"
import { useLoaderData, useNavigate } from "react-router-dom";
import { ProjectForm } from "../../components/forms/project-form";
import { toast } from "react-toastify";

export function ProjectUpdateView() {
    // @ts-ignore
    const {project, projects} = useLoaderData();
    const navigate = useNavigate();

    return <ProjectForm existing={project} projects={projects} onSubmit={project => {
        APIService.updateProject(project.id, project).then(() => {
            toast.info(`Project "${ project.name } updated"`);
            navigate("/project/" + project.key);
        })
        .catch((e: Error) => {
            toast.error(e.message);
        })
    }}/>
}