import { Link, useRouteError } from "react-router-dom";

export function ErrorView() {
    let error = useRouteError();
    console.error(error);

    return <div className="flex items-center justify-center h-screen">
        <div>
            <div className="text-9xl">Error</div>

            <Link to="/">
                <button className="p-2 mt-5 text-white bg-blue-500 rounded-sm hover:bg-blue-600">
                    Go back to Dashboard
                </button>
            </Link>
        </div>
    </div>
}