import { useDraggable } from "@dnd-kit/core";
import { Ticket } from "../model";
import { getTicketTypeIcon } from "../utils/ui";
import { SimpleListItem } from "./list-item";
import moment from "moment";

export function TicketCard(props: TicketPreviewProps) {
    const {attributes, listeners, setNodeRef} = useDraggable({
        id: props.ticket.id,
    });

    return (
        <div ref={setNodeRef} {...listeners} {...attributes}>
            <SimpleListItem url={`/ticket/${props.ticket.key}-${props.ticket.number}`}>
                <div className="p-1 w-full">
                    <div className="mb-2">
                        { props.ticket.title}
                    </div>
                    <div className="flex">
                        <div className="flex-1">
                            { getTicketTypeIcon(props.ticket.type) }

                            <div className={`inline ml-2 text-base text-gray-600 ${props.ticket.status === "DONE" || props.ticket.status === "WONT_DO" ? "line-through" : ""} dark:text-gray-400`}>
                                {props.ticket.key}-{ props.ticket.number}
                            </div>
                        </div>
                        <div className="text-xs/6 text-gray-500">
                            Last Activity: { moment(props.ticket.last_activity_at).fromNow() }
                        </div>
                    </div>
                </div>
            </SimpleListItem>
        </div>
    );
}

interface TicketPreviewProps {
    ticket: Ticket;
}