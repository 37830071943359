import { Link, useLoaderData } from "react-router-dom";
import { CommitList } from "../../components/commit-list";
import { getProviderIcon } from "../../utils/ui";
import { Button } from "@joushx/react-components";

export function RepositoryView() {
    // @ts-ignore
    const {repository, commits} = useLoaderData();

    return (
        <div className="container p-2 mx-auto md:flex md:p-0">
            <div id="main" className="flex-1">

                <div className="flex">
                    <div className="flex-1">
                        <div className="text-xl">
                            <span>Projects</span>

                            {repository.projects_app_project ?
                                <>
                                    <span className="mx-2">/</span>
                                    <Link to={`/project/${repository.projects_app_project.key}`}>
                                        <span>{repository.projects_app_project.key}</span>
                                    </Link>
                                </> : null
                            }

                            <span className="mx-2">/</span>
                            <span>{ getProviderIcon(repository.provider) } { repository.name }</span>
                        </div>
                        <h1 className="mt-5 text-3xl font-semibold">{repository.name}</h1>
                    </div>
                </div>

                <p>{ repository.description }</p>

                <div className="my-5">
                    <Link to={repository.url}>
                        <Button>
                            <i className="bi bi-box-arrow-up-right"></i> Open
                        </Button>
                    </Link>
                    <Link to={`/repository/${repository.id}/update`} className="ml-1">
                        <Button>
                            <i className="bi bi-pencil"></i> Edit
                        </Button>
                    </Link>
                </div>

                <CommitList commits={commits}/>
            </div>
        </div>
    );
}