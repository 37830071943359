import { APIService } from "../../services/api"
import { useLoaderData, useNavigate } from "react-router-dom";
import { RepositoryForm } from "../../components/forms/repository-form";
import { toast } from "react-toastify";
import { Project } from "../../model";

export function RepositoryCreateView() {
    const navigate = useNavigate();
    const projects = useLoaderData() as Project[];

    return <RepositoryForm projects={projects} onSubmit={repository => {
        APIService.createRepository(repository).then(repository => {
            toast.info(`Repository "${ repository.name } created"`);
            navigate("/repository/" + repository.id);
        }).catch(e => {
            toast.error(e);
        })
    }}/>
}