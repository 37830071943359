import { Commit, Project, Repository, Ticket } from "../model"
import { AuthService } from "./auth"

const BASE_URL = "https://api.jmittendorfer.at/projects/v1/"
const TICKET_TABLE = "projects_app_ticket";
const PROJECT_TABLE = "projects_app_project"
const REPOSITORY_TABLE = "projects_app_repository";
const COMMIT_TABLE = "projects_app_commit";

export class APIService {
    public static getTickets(filter: string): Promise<Ticket[]> {
        return this.selectList<Ticket>(TICKET_TABLE + filter)
    }

    public static getTicket(number: string): Promise<Ticket> {
        let parts = number.split("-");
        if (parts.length !== 2) {
            return Promise.reject("Ticket number malformed");
        }

        let selector = "?key=eq." + parts[0] + "&number=eq." + parts[1] + "&select=*,projects_app_project(key,name),projects_app_ticket(*),projects_app_commit(*)&projects_app_commit.order=timestamp.desc"

        return this.select<Ticket>(TICKET_TABLE + selector)
    }

    public static getProject(filter: string): Promise<Ticket> {
        return this.select<Ticket>(PROJECT_TABLE + filter)
    }

    public static getRepository(filter: string): Promise<Repository> {
        return this.select<Repository>(REPOSITORY_TABLE + filter)
    }

    public static createTicket(ticket: Ticket): Promise<Ticket> {
        return this.create<Ticket>(TICKET_TABLE, ticket);
    }

    public static createRepository(repository: Repository): Promise<Repository> {
        return this.create<Repository>(REPOSITORY_TABLE, repository);
    }

    public static createProject(project: Project): Promise<Project> {
        return this.create<Project>(PROJECT_TABLE, project);
    }

    public static updateTicket(id: number, change: any): Promise<void> {
        return this.update(TICKET_TABLE, id, change);
    }

    public static updateRepository(id: number, change: any): Promise<void> {
        return this.update(REPOSITORY_TABLE, id, change);
    }

    public static updateProject(id: number, change: any): Promise<void> {
        return this.update(PROJECT_TABLE, id, change);
    }

    public static getProjects(filter: string): Promise<Project[]> {
        return this.selectList<Project>(PROJECT_TABLE + filter)
    }

    public static getCommits(filter: string): Promise<Commit[]> {
        return this.selectList<Commit>(COMMIT_TABLE + filter)
    }

    public static getRepositories(filter: string): Promise<Repository[]> {
        return this.selectList<Repository>(REPOSITORY_TABLE + filter)
    }

    public static search(query: string): Promise<Ticket[]> {
        return this.selectList<Ticket>(TICKET_TABLE + "?or=(title.fts(english)." + query + ",description.fts(english)." + query + ")&select=*,projects_app_project(name)")
    }

    private static selectList<T>(table: string): Promise<T[]> {
        return fetch(BASE_URL + table, {
            headers: {
                "Authorization": "Bearer " + AuthService.getToken()
            }
        })
        .then(resp => this.handleResponse(resp))
        .then(resp => resp.json());
    }

    private static select<T>(table: string): Promise<T> {
        return fetch(BASE_URL + table, {
            headers: {
                "Authorization": "Bearer " + AuthService.getToken(),
                "Accept": "application/vnd.pgrst.object+json"
            }
        })
        .then(resp => this.handleResponse(resp))
        .then(resp => resp.json());
    }

    private static create<T>(table: string, object: any): Promise<T> {
        return fetch(BASE_URL + table, {
            method: "POST",
            body: JSON.stringify(object),
            headers: {
                "Authorization": "Bearer " + AuthService.getToken(),
                "Content-Type": "application/json",
                "Prefer": "return=representation",
            }
        })
        .then(resp => this.handleResponse(resp))
        .then(resp => resp.json())
        .then(objects => (objects as any)[0]);
    }

    private static update(table: string, id: number, object: any): Promise<void> {
        object = this.removeNullProperties(object);

        return fetch(BASE_URL + table + "?id=eq." + id, {
            method: "PATCH",
            body: JSON.stringify(object),
            headers: {
                "Authorization": "Bearer " + AuthService.getToken(),
                "Content-Type": "application/json"
            }
        })
        .then(resp => this.handleResponse(resp))
        .then(() => {});
    }

    private static async handleResponse(resp: Response): Promise<Response> {
        if (resp.ok) {
            return resp;
        }

        let data = await resp.json().catch(() => {
            // cannot get json, give generic error
            throw new Error(`Unexpected response code ${resp.status} ${resp.statusText}`);
        });

        if (data["message"]) {
            throw new Error(data["message"]);
        }

        throw new Error("Unknown error");
    }

    private static removeNullProperties(obj: any) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
    }
}